html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  user-select: none;
  font-family: Helvetica, sans-serif;
}

* {
  box-sizing: inherit;
}

*:before,
*:after {
  box-sizing: inherit;
}

:root {
  --bjelin-grey: #959797;
  --bjelin-middlegrey: #757575;
  --bjelin-light-grey: #dfe2e1;
  --bjelin-dark-grey: #3e3e3e;
}

html,
body {
  margin: 0;
  padding: 0;
}
html {
  scroll-snap-type: y mandatory;
}

.scroll-snap-section {
  scroll-snap-align: start;
  height: 100dvh;
}

.tiles-container > div {
  width: 100%;
  justify-content: space-around;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.debug-panel {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.7);
}
